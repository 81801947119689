const stickyButton = {
    tileListBlock: false,
    applyNowBtn: false,
    stickyButtonShowing: false,
    hideStickyBtn: () => {
        this.stickyButtonShowing = false;
        const stickyBtnWrap = document.getElementById('promoStickyButton');
        if (stickyBtnWrap) {
            stickyBtnWrap.style.opacity = '0';
            // figure out width of screen
            const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
            if (vw < 992) {
                // tablet and below = top placement
                stickyBtnWrap.style.top = '-100px';
            } else {
                // desktop = bottom placement
                stickyBtnWrap.style.bottom = '-100px';
                document.getElementsByClassName('foot')[0].style.paddingBottom = '0';
            }
        }
    },
    showStickyBtn: () => {
        this.stickyButtonShowing = true;
        const stickyBtnWrap = document.getElementById('promoStickyButton');
        if (stickyBtnWrap) {
            document.getElementById('promoStickyButton').style.opacity = '1';
            const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
            if (vw < 992) {
                // tablet and below = top placement
                document.getElementById('promoStickyButton').style.top = '0';
                document.getElementById('promoStickyButton').style.bottom = 'inherit';
            } else {
                // desktop = bottom placement
                try {
                    document.getElementById('promoStickyButton').style.bottom = '0';
                    document.getElementById('promoStickyButton').style.top = 'inherit';
                    document.getElementsByClassName('foot')[0].style.paddingBottom = '100px';
                } catch (e) {
                    // no worries just don't want console errors
                }
            }
        }
    },
    checkScrollPosition: (entries) => {
        entries.forEach((entry) => {
            if (entry.boundingClientRect.y < 0) {
                stickyButton.showStickyBtn();
            } else {
                stickyButton.hideStickyBtn();
            }
        });
    },
};

function documentReady(fn) {
    if (document.readyState !== 'loading') {
        fn();
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
}

function supportsIntersectionObserver() {
    return (
        'IntersectionObserver' in window
        && 'IntersectionObserverEntry' in window
        && 'intersectionRatio' in IntersectionObserverEntry.prototype
    );
}

documentReady(() => {
    // Check if IntersectionObserver is available. If it's not (old mobile browsers), then just show the sticky. Polyfill is available but is heavy and IMO unneeded - WJAY
    if (!supportsIntersectionObserver()) {
        document.getElementById('promoStickyButton').style.opacity = '1';
        document.getElementById('promoStickyButton').style.top = '0';
        document.getElementById('promoStickyButton').style.bottom = 'inherit';
        return;
    }

    [stickyButton.tileListBlock] = document.getElementsByClassName('trigger_sticky_offer_list');
    const options = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
    };
    const observer = new IntersectionObserver(stickyButton.checkScrollPosition, options);

    function setApplyNowBtn() {
        if (typeof stickyButton.tileListBlock !== 'undefined') {
            try {
                // Credit Cards
                [stickyButton.applyNowBtn] = window.innerWidth < 700 ? stickyButton.tileListBlock.getElementsByClassName('card-tile__mobile-btn') : stickyButton.tileListBlock.getElementsByClassName('card-tile__desktop-apply-btn');

                // NFT
                if (typeof stickyButton.applyNowBtn === 'undefined') {
                    [stickyButton.applyNowBtn] = window.innerWidth < 700 ? stickyButton.tileListBlock.getElementsByClassName('nft-marketplace-tile__mobile-btn') : stickyButton.tileListBlock.getElementsByClassName('nft-marketplace-tile__desktop-apply-btn');
                }

                // Crypto Wallet
                if (typeof stickyButton.applyNowBtn === 'undefined') {
                    [stickyButton.applyNowBtn] = window.innerWidth < 700 ? stickyButton.tileListBlock.getElementsByClassName('crypto-wallet-tile__mobile-btn') : stickyButton.tileListBlock.getElementsByClassName('crypto-wallet-tile__desktop-apply-btn');
                }

                // Insurance
                if (typeof stickyButton.applyNowBtn === 'undefined') {
                    [stickyButton.applyNowBtn] = document.querySelectorAll('.general-tile-item .btn-wrap');
                }

                // taxes
                if (typeof stickyButton.applyNowBtn === 'undefined') {
                    [stickyButton.applyNowBtn] = document.querySelectorAll('.tile-wrap.ttip__outer .btn-wrap');
                }
                // Catch
                if (typeof stickyButton.applyNowBtn === 'undefined') {
                    [stickyButton.applyNowBtn] = stickyButton.tileListBlock.getElementsByClassName('btn-wrap');
                }
                observer.observe(stickyButton.applyNowBtn);
            } catch (e) {
                // no worries just don't want console errors
            }
        }
    }

    window.addEventListener('resize', () => {
        setApplyNowBtn();
    });

    setApplyNowBtn();
});
